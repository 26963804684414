import React, { useEffect, useState } from "react";
import { Box, Typography, LinearProgress, Divider } from "@mui/material";
import api from "./../api";

const BatchProgress = ({ eventId }) => {
  const [progressData, setProgressData] = useState([]);

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const response = await api.get(`/producer/events/${eventId}/progress_by_type`);
        setProgressData(response.data.progress_by_type);
      } catch (error) {
        console.error("Erro ao carregar progresso dos batches:", error);
      }
    };

    fetchProgressData();
  }, [eventId]);

  const totalUsed = progressData.reduce((sum, group) => sum + group.used, 0);
  const totalPasses = progressData.reduce((sum, group) => sum + group.total, 0);
  const totalProgress = totalPasses > 0 ? ((totalUsed / totalPasses) * 100).toFixed(2) : 0;

  return (
    <Box sx={{ padding: 2, marginBottom: 2, backgroundColor: "#f9f9f9", borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>
        Progresso da Validação
      </Typography>

      {progressData.map((group) => (
        <Box key={group.pass_type} sx={{ marginBottom: 3 }}>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Tipo: {group.pass_type} - {group.used}/{group.total} lidos
          </Typography>
          <LinearProgress
            variant="determinate"
            value={group.progress}
            sx={{
              height: 12,
              borderRadius: 1,
              "& .MuiLinearProgress-bar": {
                backgroundColor: "primary.main",
              },
            }}
          />
        </Box>
      ))}

      <Divider sx={{ marginY: 2 }} />

      <Box>
        <Typography variant="body1" gutterBottom>
          Total: {totalUsed}/{totalPasses} lidos
        </Typography>
        <LinearProgress
          variant="determinate"
          value={totalProgress}
          sx={{
            height: 16,
            borderRadius: 1,
            "& .MuiLinearProgress-bar": {
              backgroundColor: "secondary.main",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BatchProgress;
