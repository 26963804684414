import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  MenuItem,
  Box,
} from '@mui/material';
import {
  Save as SaveIcon,
  UploadFile as UploadFileIcon,
} from '@mui/icons-material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import api from '../../api';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string(),
  cep: Yup.string().required('CEP é obrigatório'),
  state_id: Yup.number().required('Estado é obrigatório'),
  city_id: Yup.number().required('Cidade é obrigatória'),
  street_name: Yup.string().required('Nome da rua é obrigatório'),
  street_number: Yup.string().required('Número é obrigatório'),
  neighborhood: Yup.string().required('Bairro é obrigatório'),
  address_complement: Yup.string(),
  scheduled_start: Yup.string().required('Data de início é obrigatória'),
  scheduled_end: Yup.string().required('Data de fim é obrigatória'),
});

const EventEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    cep: '',
    state_id: '',
    city_id: '',
    street_name: '',
    street_number: '',
    neighborhood: '',
    address_complement: '',
    scheduled_start: '',
    scheduled_end: '',
    desktop_photo: null,
    mobile_photo: null,
    status: '',
  });

  const formatDateTime = (datetime) => {
    if (!datetime) return '';
    const date = new Date(datetime);
    const tzOffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date - tzOffset).toISOString().slice(0, 16);
    return localISOTime;
  };

  const fetchAddressByCep = async (cep, setFieldValue) => {
    try {
      const sanitizedCep = cep.replace(/\D/g, '');
      if (sanitizedCep.length === 8) {
        const response = await fetch(`https://viacep.com.br/ws/${sanitizedCep}/json/`);
        const data = await response.json();

        if (!data.erro) {
          const selectedState = states.find((state) => state.acronym === data.uf);
          if (selectedState) {
            setFieldValue('state_id', selectedState.id);

            const citiesResponse = await api.get(`/producer/states/${selectedState.id}/cities`);
            setCities(citiesResponse.data || []);

            const selectedCity = citiesResponse.data.find(
              (city) => city.name.toLowerCase() === data.localidade.toLowerCase()
            );
            if (selectedCity) {
              setFieldValue('city_id', selectedCity.id);
            } else {
              setFieldValue('city_id', '');
            }
          }

          setFieldValue('street_name', data.logradouro || '');
          setFieldValue('neighborhood', data.bairro || '');
          setFieldValue('address_complement', data.complemento || '');
        } else {
          alert('CEP não encontrado.');
        }
      }
    } catch (error) {
      console.error('Erro ao buscar endereço pelo CEP:', error);
      alert('Erro ao buscar endereço. Verifique o CEP e tente novamente.');
    }
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await api.get(`/producer/events/${id}`);
        const { event, states } = response.data;

        setStates(states || []);

        setInitialValues({
          name: event.name || '',
          description: event.description || '',
          cep: event.cep || '',
          state_id: event.state_id || '',
          city_id: event.city_id || '',
          street_name: event.street_name || '',
          street_number: event.street_number || '',
          neighborhood: event.neighborhood || '',
          address_complement: event.address_complement || '',
          scheduled_start: formatDateTime(event.scheduled_start),
          scheduled_end: formatDateTime(event.scheduled_end),
          desktop_photo: event.desktop_photo_url || null,
          mobile_photo: event.mobile_photo_url || null,
          status: event.status || '',
        });

        if (event.state_id) {
          const citiesResponse = await api.get(`/producer/states/${event.state_id}/cities`);
          setCities(citiesResponse.data || []);
        }
      } catch (error) {
        console.error('Erro ao carregar evento:', error);
        alert('Erro ao carregar evento. Tente novamente.');
      }
    };

    fetchEvent();
  }, [id]);

  useEffect(() => {
    const loadCities = async () => {
      if (initialValues.state_id) {
        const citiesResponse = await api.get(`/producer/states/${initialValues.state_id}/cities`);
        setCities(citiesResponse.data || []);
      }
    };

    loadCities();
  }, [initialValues.state_id]);

  if (!states.length || (initialValues.state_id && !cities.length)) {
    return <p>Carregando...</p>;
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4, marginBottom: 4 }}>
        <Typography variant="h5" gutterBottom>
          Informações do Evento
        </Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (new Date(values.scheduled_start) > new Date(values.scheduled_end)) {
              alert('A data de início não pode ser posterior à data de fim.');
              return;
            }

            const formData = new FormData();
            Object.keys(values).forEach((key) => {
              if (values[key] !== null && values[key] !== '') {
                if (
                  (key === 'desktop_photo' || key === 'mobile_photo') &&
                  typeof values[key] === 'string'
                ) {
                } else {
                  formData.append(`event[${key}]`, values[key]);
                }
              }
            });

            try {
              const response = await api.patch(`/producer/events/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              });

              if (response.status === 200) {
                alert('Evento atualizado com sucesso');
                navigate('/producer/events');
              } else {
                alert(`Erro ao atualizar evento: ${response.statusText}`);
              }
            } catch (error) {
              console.error('Erro ao atualizar evento:', error.response || error.message);
              alert('Erro ao atualizar evento. Verifique os dados e tente novamente.');
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    name="name"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Descrição"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Upload de Imagens</Typography>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                    sx={{ mr: 2 }}
                  >
                    Upload Imagem Desktop
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        setFieldValue('desktop_photo', e.currentTarget.files[0]);
                      }}
                    />
                  </Button>
                  {values.desktop_photo && (
                    <Box sx={{ mt: 2 }}>
                      {typeof values.desktop_photo === 'string' ? (
                        <img
                          src={values.desktop_photo}
                          alt="Imagem Desktop Existente"
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            borderRadius: 4,
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          Arquivo: {values.desktop_photo.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                  >
                    Upload Imagem Mobile
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        setFieldValue('mobile_photo', e.currentTarget.files[0]);
                      }}
                    />
                  </Button>
                  {values.mobile_photo && (
                    <Box sx={{ mt: 2 }}>
                      {typeof values.mobile_photo === 'string' ? (
                        <img
                          src={values.mobile_photo}
                          alt="Imagem Mobile Existente"
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            borderRadius: 4,
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          Arquivo: {values.mobile_photo.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="99999-999"
                    value={values.cep}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value.replace(/\D/g, '').length === 8) {
                        fetchAddressByCep(e.target.value, setFieldValue);
                      }
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="CEP"
                        name="cep"
                        fullWidth
                        error={touched.cep && Boolean(errors.cep)}
                        helperText={touched.cep && errors.cep}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Estado"
                    name="state_id"
                    fullWidth
                    value={values.state_id}
                    onChange={async (e) => {
                      handleChange(e);
                      setFieldValue('city_id', '');
                      const stateId = e.target.value;
                      if (stateId) {
                        const citiesResponse = await api.get(`/producer/states/${stateId}/cities`);
                        setCities(citiesResponse.data || []);
                      } else {
                        setCities([]);
                      }
                    }}
                    error={touched.state_id && Boolean(errors.state_id)}
                    helperText={touched.state_id && errors.state_id}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Cidade"
                    name="city_id"
                    fullWidth
                    value={cities.some((city) => city.id === values.city_id) ? values.city_id : ''}
                    onChange={handleChange}
                    error={touched.city_id && Boolean(errors.city_id)}
                    helperText={touched.city_id && errors.city_id}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nome da Rua"
                    name="street_name"
                    fullWidth
                    value={values.street_name}
                    onChange={handleChange}
                    error={touched.street_name && Boolean(errors.street_name)}
                    helperText={touched.street_name && errors.street_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Número"
                    name="street_number"
                    fullWidth
                    value={values.street_number}
                    onChange={handleChange}
                    error={touched.street_number && Boolean(errors.street_number)}
                    helperText={touched.street_number && errors.street_number}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Bairro"
                    name="neighborhood"
                    fullWidth
                    value={values.neighborhood}
                    onChange={handleChange}
                    error={touched.neighborhood && Boolean(errors.neighborhood)}
                    helperText={touched.neighborhood && errors.neighborhood}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Complemento do Endereço"
                    name="address_complement"
                    fullWidth
                    value={values.address_complement}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Início"
                    type="datetime-local"
                    name="scheduled_start"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.scheduled_start}
                    onChange={handleChange}
                    error={touched.scheduled_start && Boolean(errors.scheduled_start)}
                    helperText={touched.scheduled_start && errors.scheduled_start}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Fim"
                    type="datetime-local"
                    name="scheduled_end"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.scheduled_end}
                    onChange={handleChange}
                    error={touched.scheduled_end && Boolean(errors.scheduled_end)}
                    helperText={touched.scheduled_end && errors.scheduled_end}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Status"
                    name="status"
                    fullWidth
                    value={values.status}
                    onChange={handleChange}
                    error={touched.status && Boolean(errors.status)}
                    helperText={touched.status && errors.status}
                  >
                    <MenuItem value="active">Ativo</MenuItem>
                    <MenuItem value="deactivated">Desativado</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Salvando...' : 'Salvar'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default EventEdit;
