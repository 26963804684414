import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Typography, Paper } from "@mui/material";

const CityDistributionChart = ({ orders }) => {
  const [cityData, setCityData] = useState([]);

  useEffect(() => {
    const processCityData = () => {
      if (!orders || orders.length === 0) {
        return;
      }

      const cityCount = {};

      orders.forEach((order) => {
        const city = order.user?.city?.name;
        if (city) {
          cityCount[city] = (cityCount[city] || 0) + 1;
        }
      });

      const chartData = Object.entries(cityCount).map(([city, count]) => ({
        name: city,
        value: count,
      }));

      setCityData(chartData);
    };

    processCityData();
  }, [orders]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF4567", "#AA36D3"];

  return (
    <Paper elevation={3} sx={{ padding: 4, marginBottom: 3 }}>
      <Typography variant="h5" gutterBottom>
        Distribuição de Cidades por Faturas
      </Typography>
      {cityData.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          Nenhum dado disponível para exibição.
        </Typography>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={cityData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              label={(entry) => `${entry.name} (${entry.value})`}
            >
              {cityData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default CityDistributionChart;
