import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, Grid, MenuItem } from '@mui/material';
import api from '../api';

const EditProfileForm = ({ user, onUpdate }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      document_number: user?.document_number || '',
      phone_number: user?.phone_number || '',
      cep: user?.cep || '',
      street: user?.street || '',
      number: user?.number || '',
      complement: user?.complement || '',
      district: user?.district || '',
      city_id: user?.city_id || '',
      state_id: user?.state_id || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Nome é obrigatório'),
      email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
      document_number: Yup.string().required('Documento é obrigatório'),
      phone_number: Yup.string().required('Telefone é obrigatório'),
      cep: Yup.string().required('CEP é obrigatório'),
      street: Yup.string().required('Rua é obrigatória'),
      number: Yup.string().required('Número é obrigatório'),
      district: Yup.string().required('Bairro é obrigatório'),
      city_id: Yup.number().required('Cidade é obrigatória'),
      state_id: Yup.number().required('Estado é obrigatório'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await api.put(`/users/${user.id}`, values);
        onUpdate(response.data);
        alert('Perfil atualizado com sucesso!');
      } catch (error) {
        console.error('Erro ao atualizar perfil:', error.response?.data || error.message);
        alert('Erro ao atualizar perfil. Verifique os dados e tente novamente.');
      }
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get('/states');
        setStates(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (formik.values.state_id) {
      const fetchCities = async () => {
        setLoadingCities(true);
        try {
          const response = await api.get(`/states/${formik.values.state_id}/cities`);
          const citiesData = response.data;
          setCities(citiesData);
  
          if (!citiesData.some(city => city.id === formik.values.city_id)) {
            formik.setFieldValue("city_id", "");
          }
        } catch (error) {
          console.error('Erro ao carregar cidades:', error);
          setCities([]);
          formik.setFieldValue("city_id", "");
        } finally {
          setLoadingCities(false);
        }
      };
  
      fetchCities();
    } else {
      setCities([]);
      formik.setFieldValue("city_id", "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.state_id]);  

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Editar Perfil
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Documento"
            name="document_number"
            value={formik.values.document_number}
            onChange={formik.handleChange}
            error={formik.touched.document_number && Boolean(formik.errors.document_number)}
            helperText={formik.touched.document_number && formik.errors.document_number}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Telefone"
            name="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
            helperText={formik.touched.phone_number && formik.errors.phone_number}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="CEP"
            name="cep"
            value={formik.values.cep}
            onChange={formik.handleChange}
            error={formik.touched.cep && Boolean(formik.errors.cep)}
            helperText={formik.touched.cep && formik.errors.cep}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Rua"
            name="street"
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Número"
            name="number"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Complemento"
            name="complement"
            value={formik.values.complement}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Bairro"
            name="district"
            value={formik.values.district}
            onChange={formik.handleChange}
            error={formik.touched.district && Boolean(formik.errors.district)}
            helperText={formik.touched.district && formik.errors.district}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            label="Estado"
            name="state_id"
            value={formik.values.state_id}
            onChange={formik.handleChange}
            error={formik.touched.state_id && Boolean(formik.errors.state_id)}
            helperText={formik.touched.state_id && formik.errors.state_id}
          >
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            label="Cidade"
            name="city_id"
            value={formik.values.city_id}
            onChange={formik.handleChange}
            error={formik.touched.city_id && Boolean(formik.errors.city_id)}
            helperText={formik.touched.city_id && formik.errors.city_id}
            disabled={loadingCities || !formik.values.state_id}
          >
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
      >
        Salvar Alterações
      </Button>
    </Box>
  );
};

export default EditProfileForm;
