import React, { createContext, useState, useContext, useCallback } from "react";
import api from "../api";

const ProducerContext = createContext();

export const useProducer = () => useContext(ProducerContext);

const ProducerProvider = ({ children }) => {
  const [producer, setProducer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCurrentProducer = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/producers/current");
      setProducer(response.data);
    } catch (err) {
      setError("Erro ao carregar o produtor. Tente novamente.");
      console.error("Erro ao carregar produtor:", err);
    } finally {
      setLoading(false);
    }
  }, []);  

  return (
    <ProducerContext.Provider value={{ producer, fetchCurrentProducer, loading, error }}>
      {children}
    </ProducerContext.Provider>
  );
};

export default ProducerProvider;
