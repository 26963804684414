import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import { QrCodeScanner } from "@mui/icons-material";
import api from "../../api";
import { AuthContext } from "../../contexts/AuthContext";

const EventList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get(`/assistant/events/by_producer`);
        setEvents(response.data);
      } catch (error) {
        console.error("Erro ao carregar eventos do produtor:", error);
        alert("Erro ao carregar eventos.");
      }
    };    
  
    if (user?.id) {
      fetchEvents();
    }
  }, [user]);  

  const handleOpenScanner = (eventId) => {
    if (user?.id) {
      navigate(`/scanner/${user.id}?eventId=${eventId}`);
    } else {
      alert("Usuário não encontrado. Verifique sua autenticação.");
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Lista de Eventos
        </Typography>
        <Grid container spacing={3}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card variant="outlined" sx={{ padding: 2 }}>
                <CardContent>
                  <Typography variant="h6">{event.name}</Typography>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={<QrCodeScanner />}
                      sx={{ width: "100%" }}
                      onClick={() => handleOpenScanner(event.id)}
                    >
                      Validar Ingressos
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default EventList;
