import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';
import CompletedPasses from '../components/CompletedPasses';
import PendingOrders from '../components/PendingOrders';
import api from '../api';
import EditProfileForm from '../components/EditProfileForm';
import { generatePDF } from '../utils/pdfGenerator';

const ProfilePage = () => {
  const { user, isAuthenticated, setUser } = useContext(AuthContext);
  const [completedPasses, setCompletedPasses] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchPassesAndOrders = async () => {
      try {
        const completedResponse = await api.get('/orders', { params: { invoice_status: 'paid' } });
        const ordersWithPaidStatus = completedResponse.data.filter(
          (order) => order.invoice_status === 'paid'
        );

        const passes = ordersWithPaidStatus.flatMap((order) =>
          order.passes.map((pass) => ({
            id: pass.id,
            name: order.event?.name || 'Evento não definido',
            date: order.event?.scheduled_start
              ? new Date(order.event.scheduled_start).toLocaleDateString('pt-BR')
              : 'Data não definida',
            location: order.event?.state?.acronym && order.event?.city?.name
              ? `${order.event.state.acronym}, ${order.event.city.name}`
              : 'Local não definido',
            createdAt: order.created_at
              ? new Date(order.created_at).toLocaleDateString('pt-BR')
              : 'Data de criação não disponível',
            qrcodeUrl: pass.qrcode_svg || '',
          }))
        );

        setCompletedPasses(passes);

        const pendingResponse = await api.get('/orders', { params: { invoice_status: 'pending' } });
        const orders = pendingResponse.data.map((order) => ({
          id: order.id,
          name: order.event?.name || 'Evento não definido',
          date: order.event?.scheduled_start
            ? new Date(order.event.scheduled_start).toLocaleDateString('pt-BR')
            : 'Data não definida',
          location: order.event?.state?.acronym && order.event?.city?.name
            ? `${order.event.state.acronym}, ${order.event.city.name}`
            : 'Local não definido',
          createdAt: order.created_at
            ? new Date(order.created_at).toLocaleDateString('pt-BR')
            : 'Data de criação não disponível',
          invoiceUrl: order.invoice_url || '',
        }));
        setPendingOrders(orders);
      } catch (error) {
        console.error('Erro ao carregar ingressos e pedidos:', error);
      }
    };

    if (isAuthenticated) {
      fetchPassesAndOrders();
    }
  }, [isAuthenticated]);

  const handleViewPass = (passId) => {
    navigate(`/passes/${passId}`);
  };

  const handlePayOrder = (orderId) => {
    navigate(`/orders/${orderId}/invoice`);
  };

  if (!user) {
    return (
      <Box sx={{ p: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
        <Typography variant="h5" align="center">
          Carregando...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Bem-vindo, {user?.name || 'Usuário'}!
      </Typography>
      <Typography variant="body1" align="center" sx={{ mb: 4 }}>
        Aqui você pode acessar seus ingressos e concluir pedidos pendentes.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Meus Ingressos
      </Typography>
      <CompletedPasses
        passes={completedPasses}
        onViewPass={handleViewPass}
        onDownloadPass={generatePDF}
      />

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" gutterBottom>
        Pedidos Pendentes
      </Typography>
      <PendingOrders orders={pendingOrders} onPayOrder={handlePayOrder} />

      <EditProfileForm user={user} onUpdate={(updatedUser) => setUser(updatedUser)} />

    </Box>
  );
};

export default ProfilePage;
