import React, { createContext, useState, useCallback, useMemo } from 'react';
import api from '../api';

export const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get('/orders');
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderById = useCallback(async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/orders/${id}`);
      setOrder(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching order:', error);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);
  

  const createOrder = async (orderData) => {
    setLoading(true);
    try {
      const response = await api.post('/orders', orderData);
      setOrders((prevOrders) => [...prevOrders, response.data]);
      return response.data;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const addEventBatchesToOrder = async (id, eventBatchesData) => {
    setLoading(true);
    try {
      const response = await api.patch(`/orders/${id}/update_with_batches`, { event_batches: eventBatchesData });
      setOrder(response.data);
      setOrders((prevOrders) =>
        prevOrders.map((order) => (order.id === id ? response.data : order))
      );
      return response.data;
    } catch (error) {
      console.error('Error adding event batches to order:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const memoizedOrder = useMemo(() => order, [order]);

  return (
    <OrderContext.Provider
      value={{
        orders,
        order: memoizedOrder,
        loading,
        fetchOrders,
        fetchOrderById,
        createOrder,
        addEventBatchesToOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
