import React, { useState, useEffect } from "react";
import { Box, Grid, Card, CardContent, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../../api";

const AdminEventList = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get("/admin/events");
        setEvents(response.data);
      } catch (error) {
        console.error("Erro ao carregar eventos:", error);
        alert("Erro ao carregar eventos.");
      }
    };

    fetchEvents();
  }, []);

  const handleViewDetails = (eventId) => {
    navigate(`/admin/events/${eventId}/details`);
  };

  const handleEditFeePercentage = (eventId) => {
    navigate(`/admin/events/${eventId}/update-fee-percentage`);
  };

  const handleEventOrdersDetails = (eventId) => {
    navigate(`/admin/events/${eventId}/orders`);
  };

  const handleEventInsuranceDetails = (eventId) => {
    navigate(`/admin/events/${eventId}/update-insurance-details`);
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Lista de Eventos
        </Typography>
        <Grid container spacing={3}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card variant="outlined" sx={{ padding: 2 }}>
                <CardContent>
                  <Typography variant="h6">{event.name}</Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    Data de Início: {new Date(event.scheduled_start).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    Data de Fim: {new Date(event.scheduled_end).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    Local: {event.street_name}, {event.street_number}, {event.neighborhood},{" "}
                    {event.city_name}, {event.state_name}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleViewDetails(event.id)}
                  >
                    Ver Detalhes
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleEventOrdersDetails(event.id)}
                  >
                    Ver Faturas
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 2 }}
                    onClick={() => handleEditFeePercentage(event.id)}
                  > Editar Taxa de Serviço
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 2 }}
                    onClick={() => handleEventInsuranceDetails(event.id)}
                  >
                    Editar Seguro
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default AdminEventList;