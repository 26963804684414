import React, { useState, useCallback, useEffect } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useParams, useLocation } from "react-router-dom";
import { Box, Button, TextField, Typography, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ReplayIcon from "@mui/icons-material/Replay";
import api from "./../api";
import BatchProgress from "./BatchProgress";

const PassScanner = () => {
  const { scannerUserId } = useParams();
  const location = useLocation();
  const [eventId, setEventId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [scanResult, setScanResult] = useState(null);
  const [error, setError] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setEventId(queryParams.get("eventId"));
  }, [location]);

  const handleValidation = useCallback(
    async (passIdentifier) => {
      if (!eventId) {
        setError("Evento não especificado.");
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await api.post(`/passes/${passIdentifier}/scan`, {
          scanner_user_id: scannerUserId,
          event_id: eventId,
        });
        setScanResult(response.data);
      } catch (err) {
        if (err.response?.data?.message) {
          setError(err.response.data.message);
        } else {
          setError("Erro desconhecido.");
        }
        setScanResult(null);
      } finally {
        setLoading(false);
      }
    },
    [scannerUserId, eventId]
  );

  const startCameraScanner = async () => {
    setCameraActive(true);

    try {
      const devices = await Html5Qrcode.getCameras();
      if (devices && devices.length) {
        const backCamera = devices.find((d) => d.label.toLowerCase().includes("back")) || devices[0];

        const config = { fps: 10, qrbox: 250 };
        const qrCodeScanner = new Html5Qrcode("qr-reader");

        await qrCodeScanner.start(
          backCamera.id,
          config,
          (decodedText) => {
            qrCodeScanner
              .stop()
              .then(() => {
                setCameraActive(false);
                handleValidation(decodedText);
              })
              .catch((err) => {
                console.error("Erro ao parar o scanner:", err);
                setCameraActive(false);
              });
          },
          (errorMessage) => {
            console.warn(`Erro na leitura do QR Code: ${errorMessage}`);
          }
        );
      } else {
        setError("Nenhuma câmera disponível foi encontrada.");
        setCameraActive(false);
      }
    } catch (err) {
      console.error("Erro ao acessar a câmera:", err);
      setError("Erro ao iniciar o scanner. Verifique as permissões da câmera.");
      setCameraActive(false);
    }
  };

  const resetScanner = () => {
    setScanResult(null);
    setError(null);
    setIdentifier("");
  };

  return (
    <Box sx={{ textAlign: "center", padding: 4 }}>
      {eventId && <BatchProgress eventId={eventId} />}
      {!scanResult && !error && !loading && (
        <Box>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Validação de Passes do Evento {eventId}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={startCameraScanner}
            sx={{ marginBottom: 2 }}
            disabled={cameraActive}
          >
            {cameraActive ? "Câmera Ativa..." : "Validar com a Câmera"}
          </Button>
          <Box sx={{ marginY: 2 }}>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              Ou digite o identificador do passe:
            </Typography>
            <TextField
              label="Identificador"
              variant="outlined"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              sx={{ width: "100%", marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleValidation(identifier)}
              disabled={!identifier.trim()}
            >
              Validar Identificador
            </Button>
          </Box>
          {cameraActive && <div id="qr-reader" style={{ width: "100%", marginTop: 20 }}></div>}
        </Box>
      )}

      {loading && <CircularProgress sx={{ marginTop: 2 }} />}

      {!loading && scanResult && (
        <Box>
          <Typography variant="h5" color="success.main" sx={{ marginBottom: 2 }}>
            <CheckCircleIcon sx={{ marginRight: 1 }} />
            Validação Concluída
          </Typography>
          <Typography variant="body1">
            <strong>Resultado:</strong> {scanResult.result ? "Passe válido!" : "Passe inválido."}
          </Typography>
          <Typography variant="body1">
            <strong>Mensagem:</strong> {scanResult.message || "Sem mensagem adicional."}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ReplayIcon />}
            onClick={resetScanner}
            sx={{ marginTop: 2 }}
          >
            Validar Outro Passe
          </Button>
        </Box>
      )}

      {!loading && error && (
        <Box>
          <Typography variant="h5" color="error.main" sx={{ marginBottom: 2 }}>
            <ErrorIcon sx={{ marginRight: 1 }} />
            Erro na Validação
          </Typography>
          <Typography variant="body1">{error}</Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ReplayIcon />}
            onClick={resetScanner}
            sx={{ marginTop: 2 }}
          >
            Tentar Novamente
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PassScanner;