import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Snackbar,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, Delete, Download, Send } from "@mui/icons-material";
import api from "../../../api";
import { generatePDF } from "../../../utils/pdfGenerator";
import EventBatchesList from "../../../components/EventBatchesList";

const AdminEventDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [passes, setPasses] = useState([]);
  const [filteredPasses, setFilteredPasses] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchPasses = async () => {
      try {
        const response = await api.get(`/admin/events/${id}/passes`);
        setPasses(response.data);
        setFilteredPasses(response.data);
      } catch (error) {
        console.error("Erro ao carregar passes:", error);
        alert("Erro ao carregar passes.");
      }
    };

    fetchPasses();
  }, [id]);

  useEffect(() => {
    const lowerCaseSearch = searchTerm.toLowerCase();
    const filtered = passes.filter((pass) =>
      [
        pass.name?.toLowerCase(),
        pass.email?.toLowerCase(),
        pass.phone?.toLowerCase(),
        pass.cpf?.toLowerCase(),
        String(pass.id),
        String(pass.order_id),
        String(pass.event_batch_id),
        String(pass.user_id),
      ].some((field) => field && field.includes(lowerCaseSearch))
    );
    setFilteredPasses(filtered);
  }, [searchTerm, passes]);

  const handleDeleteClick = () => {
    setSnackbarOpen(true);
  };

  const handleSendClick = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Box>
        <EventBatchesList />
      </Box>

      <Paper elevation={3} sx={{ padding: 4, marginTop: 2 }}>
        <Typography variant="h5" gutterBottom>
          Passes do Evento
        </Typography>
        <TextField
          fullWidth
          label="Pesquisar passes"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Digite os dados do passe que deseja buscar"
          sx={{ marginBottom: 2 }}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Nome</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Telefone</strong></TableCell>
                <TableCell><strong>CPF</strong></TableCell>
                <TableCell><strong>Order ID</strong></TableCell>
                <TableCell><strong>ID do Lote</strong></TableCell>
                <TableCell><strong>ID do Usuário</strong></TableCell>
                <TableCell align="center"><strong>Ações</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPasses.map((pass) => (
                <TableRow key={pass.id}>
                  <TableCell>{pass.id}</TableCell>
                  <TableCell>{pass.name}</TableCell>
                  <TableCell>{pass.email}</TableCell>
                  <TableCell>{pass.phone}</TableCell>
                  <TableCell>{pass.cpf}</TableCell>
                  <TableCell>{pass.order_id}</TableCell>
                  <TableCell>{pass.event_batch_id}</TableCell>
                  <TableCell>{pass.user_id}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Visualizar">
                      <IconButton
                        color="primary"
                        onClick={() => navigate(`/passes/${pass.id}`)}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Baixar PDF">
                      <IconButton
                        color="secondary"
                        onClick={() => generatePDF(pass.id)}
                      >
                        <Download />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                      <IconButton
                        color="error"
                        onClick={handleDeleteClick}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reenviar Passe">
                      <IconButton
                        color="info"
                        onClick={handleSendClick}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {filteredPasses.length === 0 && (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Nenhum passe encontrado.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Funcionalidade ainda não disponível."
      />
    </Box>
  );
};

export default AdminEventDetails;