import React, { useState, useContext } from 'react';
import moment from 'moment';
import {
  Typography,
  Box,
  Paper,
  Divider,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  ShoppingCart,
  AddCircle,
  RemoveCircle,
  CheckCircle,
  Error,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from './../api';
import { AuthContext } from '../contexts/AuthContext';
import AuthModal from './AuthModal';

export default function EventOrderItems(props) {
  const eventBatches = props.eventBatches || [];
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(AuthContext);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const initialBatchesState = eventBatches.map((eventBatch) => {
    const priceInCents = Number(eventBatch.price_in_cents) || 0;
    const feePercentage = Number(props.feePercentage) || 0;
    const feeInCents = priceInCents * (feePercentage / 100);
    const totalInCents = priceInCents + feeInCents;

    return {
      id: eventBatch.id,
      passType: eventBatch.pass_type,
      name: eventBatch.name,
      ends_at: eventBatch.ends_at,
      priceInCents: priceInCents,
      feeInCents: feeInCents,
      totalInCents: totalInCents,
      originalPriceInCents: priceInCents,
      originalFeeInCents: feeInCents,
      originalTotalInCents: totalInCents,
      quantity: 0,
      isSoldOut:
        eventBatch.quantity === 0 || new Date(eventBatch.ends_at) < new Date(),
    };
  });

  const [batchesInfosAndQuantities, setBatchesInfosAndQuantities] = useState(
    initialBatchesState
  );
  const [couponCode, setCouponCode] = useState('');
  const [couponResult, setCouponResult] = useState(null);
  const [insuranceSelected, setInsuranceSelected] = useState(true);

  const updateQuantity = (batchIndex, amount) => {
    const currentBatches = [...batchesInfosAndQuantities];
    const editedBatch = currentBatches[batchIndex];
    if (editedBatch.quantity === 0 && amount < 0) return;
    editedBatch.quantity += amount;
    setBatchesInfosAndQuantities(currentBatches);
  };

  const cartTotalInCents = () => {
    const batchesTotal = batchesInfosAndQuantities.reduce((sum, batch) => {
      return sum + batch.quantity * batch.totalInCents;
    }, 0);
  
    const totalQuantity = batchesInfosAndQuantities.reduce(
      (sum, batch) => sum + batch.quantity,
      0
    );
    const insuranceTotal = insuranceSelected
      ? totalQuantity * Number(props.insurancePriceInCents || 0)
      : 0;
  
    return batchesTotal + insuranceTotal;
  };  

  const handleInsuranceSelection = (event) => {
    setInsuranceSelected(event.target.checked);
  };

  const applyCoupon = async () => {
    if (!couponCode) {
      setBatchesInfosAndQuantities((prevBatches) =>
        prevBatches.map((batch) => ({
          ...batch,
          priceInCents: batch.originalPriceInCents,
          feeInCents: batch.originalFeeInCents,
          totalInCents: batch.originalTotalInCents,
        }))
      );
      setCouponResult(null);
      return;
    }

    try {
      const response = await api.get(
        `/coupons/${couponCode}?event_id=${props.event.id}`
      );

      if (response.data.success) {
        const couponData = response.data.coupon;

        const discountByBatchId = {};
        couponData.batches.forEach((couponBatch) => {
          discountByBatchId[couponBatch.event_batch_id] = {
            discount: Number(couponBatch.discount) || 0,
            kind: couponBatch.kind,
          };
        });

        setBatchesInfosAndQuantities((prevBatches) =>
          prevBatches.map((batch) => {
            const coupon = discountByBatchId[batch.id];
            if (coupon) {
              const discountValue = coupon.discount;
              const feePercentage = Number(props.feePercentage) || 0;
              const originalPriceInCents = batch.originalPriceInCents;

              const newPriceInCents =
                coupon.kind === 'percentage'
                  ? originalPriceInCents * (1 - discountValue / 100)
                  : originalPriceInCents - discountValue * 100;

              const newPriceInCentsClamped = Math.max(newPriceInCents, 0);

              const newFeeInCents =
                newPriceInCentsClamped * (feePercentage / 100);
              const newTotalInCents = newPriceInCentsClamped + newFeeInCents;

              return {
                ...batch,
                priceInCents: newPriceInCentsClamped,
                feeInCents: newFeeInCents,
                totalInCents: newTotalInCents,
              };
            } else {
              return {
                ...batch,
                priceInCents: batch.originalPriceInCents,
                feeInCents: batch.originalFeeInCents,
                totalInCents: batch.originalTotalInCents,
              };
            }
          })
        );

        setCouponResult({
          success: true,
          message: 'Cupom aplicado aos lotes elegíveis.',
        });
      } else {
        setBatchesInfosAndQuantities((prevBatches) =>
          prevBatches.map((batch) => ({
            ...batch,
            priceInCents: batch.originalPriceInCents,
            feeInCents: batch.originalFeeInCents,
            totalInCents: batch.originalTotalInCents,
          }))
        );
        setCouponResult({ success: false, message: response.data.error });
      }
    } catch (error) {
      setBatchesInfosAndQuantities((prevBatches) =>
        prevBatches.map((batch) => ({
          ...batch,
          priceInCents: batch.originalPriceInCents,
          feeInCents: batch.originalFeeInCents,
          totalInCents: batch.originalTotalInCents,
        }))
      );
      setCouponResult({ success: false, message: 'Erro ao buscar cupom' });
    }
  };

  const handleCreateOrder = async () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }

    const selectedItems = batchesInfosAndQuantities
      .filter((batch) => batch.quantity > 0)
      .map((batch) => ({
        event_batch_id: batch.id,
        quantity: batch.quantity,
      }));

    const totalInsurance = insuranceSelected
      ? batchesInfosAndQuantities.reduce(
          (sum, batch) => sum + batch.quantity,
          0
        ) * Number(props.insurancePriceInCents || 0)
      : 0;

    const payload = {
      order: {
        event_id: props.event.id,
        event_batches: selectedItems,
        coupon_code: couponCode,
        total_insurance_in_cents: totalInsurance,
      },
    };

    try {
      const response = await api.post('/orders', payload);

      if (response.status === 201 && response.data.order?.id) {
        navigate(`/orders/${response.data.order.id}/passes`);
      }
    } catch (error) {
      console.error('Erro ao criar a ordem:', error);
    }
  };

  return (
    <>
      <AuthModal
        open={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onAuthSuccess={handleCreateOrder}
      />
      <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h5" color="primary">
            Ingressos
          </Typography>
          <Box display="flex" alignItems="center">
            <ShoppingCart
              fontSize="large"
              sx={{ color: 'primary.main', mr: 1 }}
            />
            <Typography variant="h6" color="primary">
              {(cartTotalInCents() / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="textSecondary">
            Total do Seguro:
          </Typography>
          <Typography variant="body2" color="primary">
            {(insuranceSelected
              ? batchesInfosAndQuantities.reduce(
                  (sum, batch) => sum + batch.quantity,
                  0
                ) *
                (props.insurancePriceInCents / 100)
              : 0
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Box>

        <Divider sx={{ marginY: 2 }} />

        <Box>
          {batchesInfosAndQuantities.map((batch, index) => (
            <Box
              key={batch.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Box>
                <Typography variant="subtitle1" color="textPrimary">
                  {batch.passType} - {batch.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {(batch.priceInCents / 100).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  {props.feePercentage > 0 &&
                    ` (+ ${(batch.feeInCents / 100).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })} taxa)`}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Vendas até {moment(batch.ends_at).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                {batch.isSoldOut ? (
                  <Typography variant="body2" color="error">
                    Esgotado
                  </Typography>
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      onClick={() => updateQuantity(index, -1)}
                    >
                      <RemoveCircle />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{ width: 30, textAlign: 'center' }}
                    >
                      {batch.quantity}
                    </Typography>
                    <IconButton
                      color="primary"
                      onClick={() => updateQuantity(index, 1)}
                    >
                      <AddCircle />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>

        <Divider sx={{ marginY: 2 }} />

        {props.insuranceEnabled && (
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={insuranceSelected}
                  onChange={handleInsuranceSelection}
                  color="primary"
                />
              }
              label={`Adicionar Seguro (${(
                props.insurancePriceInCents / 100
              ).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })})`}
            />
          </Box>
        )}

        <Divider sx={{ marginY: 2 }} />

        <Box display="flex" alignItems="center" mb={3}>
          <TextField
            label="Cupom de desconto"
            variant="outlined"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            fullWidth
            sx={{ maxWidth: 300 }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={applyCoupon}
            sx={{ marginLeft: 2 }}
          >
            Aplicar
          </Button>
        </Box>

        {couponResult && (
          <Box display="flex" alignItems="center" mb={2}>
            {couponResult.success ? (
              <>
                <CheckCircle color="primary" />
                <Typography variant="body2" color="primary" sx={{ ml: 1 }}>
                  {couponResult.message}
                </Typography>
              </>
            ) : (
              <>
                <Error color="error" />
                <Typography variant="body2" color="error" sx={{ ml: 1 }}>
                  {couponResult.message}
                </Typography>
              </>
            )}
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleCreateOrder}
        >
          Comprar Ingressos
        </Button>
      </Paper>
    </>
  );
}
