/* global loadTagManager */

import React, { useEffect, useContext } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from "yup";
import api from "../../api";
import { OrderContext } from "../../contexts/OrderContext";

const PassDetailsForm = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { fetchOrderById, order } = useContext(OrderContext);

  useEffect(() => {
    fetchOrderById(orderId);
  }, [orderId, fetchOrderById]);

  useEffect(() => {
    if (order?.producer?.tag_manager_id) {
      loadTagManager(order.producer.tag_manager_id);
    }
  }, [order?.producer?.tag_manager_id]);

  const formik = useFormik({
    initialValues: {
      passes: [],
    },
    validationSchema: Yup.object().shape({
      passes: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Nome é obrigatório"),
          email: Yup.string().email("Email inválido").required("Email é obrigatório"),
          phone: Yup.string()
            .matches(/^\(\d{2}\) \d{5}-\d{4}$/, "Telefone inválido, formato esperado: (XX) XXXXX-XXXX")
            .required("Telefone é obrigatório"),
          cpf: Yup.string()
            .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido, formato esperado: XXX.XXX.XXX-XX")
            .required("CPF é obrigatório"),
        })
      ),
    }),
    onSubmit: async (values) => {
      try {
        await Promise.all(
          values.passes.map((passItem) =>
            api.put(`/passes/${passItem.id}`, {
              pass: {
                name: passItem.name,
                email: passItem.email,
                phone: passItem.phone,
                cpf: passItem.cpf,
              },
            })
          )
        );
        alert("Dados dos ingressos atualizados com sucesso!");
        navigate(`/orders/${orderId}/invoice`);
      } catch (error) {
        console.error("Erro ao atualizar os ingressos:", error);
      }
    },
  });

  useEffect(() => {
    if (
      order &&
      Array.isArray(order.passes) &&
      order.passes.length > 0 &&
      formik.values.passes.length === 0
    ) {
      const newValues = order.passes.map((p) => ({
        id: p.id,
        name: p.name || "",
        email: p.email || "",
        phone: p.phone || "",
        cpf: p.cpf || "",
      }));
      formik.setValues({ passes: newValues });
    }
  }, [order, formik]);

  const fillFirstPassWithUserData = async () => {
    try {
      const response = await api.get(`/users/${order.user_id}`);
      const userData = response.data;
  
      const formattedPhone = userData.phone_number.replace(
        /^(\d{2})(\d{5})(\d{4})$/,
        "($1) $2-$3"
      );
      const formattedCpf = userData.document_number.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        "$1.$2.$3-$4"
      );
  
      formik.setFieldValue("passes[0]", {
        ...formik.values.passes[0],
        name: userData.name || "",
        email: userData.email || "",
        phone: formattedPhone || "",
        cpf: formattedCpf || "",
      });
    } catch (error) {
      console.error("Erro ao carregar dados do usuário:", error);
      alert("Erro ao carregar dados do usuário.");
    }
  };
  

  return (
    <FormikProvider value={formik}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ p: 3, maxWidth: 600, mx: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Dados dos Ingressos
        </Typography>
        <Typography variant="body1" gutterBottom>
          Insira os dados de cada ingresso selecionado:
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          sx={{ mb: 2 }}
          onClick={fillFirstPassWithUserData}
        >
          Preencher o primeiro ingresso com seus dados
        </Button>

        <FieldArray name="passes">
          {() => (
            <>
              {formik.values.passes.length > 0 ? (
                formik.values.passes.map((passItem, index) => (
                  <Box
                    key={passItem.id || index}
                    sx={{ mb: 4, p: 2, border: 1, borderColor: "grey.300", borderRadius: 1 }}
                  >
                    <Typography variant="h6">Ingresso #{index + 1}</Typography>
                    <TextField
                      fullWidth
                      label="Nome Completo"
                      name={`passes[${index}].name`}
                      value={formik.values.passes[index].name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.passes?.[index]?.name && !!formik.errors.passes?.[index]?.name}
                      helperText={formik.touched.passes?.[index]?.name && formik.errors.passes?.[index]?.name}
                      sx={{ my: 1 }}
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      name={`passes[${index}].email`}
                      value={formik.values.passes[index].email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.passes?.[index]?.email && !!formik.errors.passes?.[index]?.email}
                      helperText={formik.touched.passes?.[index]?.email && formik.errors.passes?.[index]?.email}
                      sx={{ my: 1 }}
                    />
                    <InputMask
                      mask="(99) 99999-9999"
                      name={`passes[${index}].phone`}
                      value={formik.values.passes[index].phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          fullWidth
                          label="Telefone"
                          error={formik.touched.passes?.[index]?.phone && !!formik.errors.passes?.[index]?.phone}
                          helperText={formik.touched.passes?.[index]?.phone && formik.errors.passes?.[index]?.phone}
                          sx={{ my: 1 }}
                        />
                      )}
                    </InputMask>
                    <InputMask
                      mask="999.999.999-99"
                      name={`passes[${index}].cpf`}
                      value={formik.values.passes[index].cpf}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          fullWidth
                          label="CPF"
                          error={formik.touched.passes?.[index]?.cpf && !!formik.errors.passes?.[index]?.cpf}
                          helperText={formik.touched.passes?.[index]?.cpf && formik.errors.passes?.[index]?.cpf}
                          sx={{ my: 1 }}
                        />
                      )}
                    </InputMask>
                  </Box>
                ))
              ) : (
                <Typography variant="body2">Nenhum passe encontrado.</Typography>
              )}
            </>
          )}
        </FieldArray>

        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
          Confirmar ingressos
        </Button>
      </Box>
    </FormikProvider>
  );
};

export default PassDetailsForm;