import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useTheme } from '@mui/material/styles';

import produtoreshome from '../assets/produtoreshome.png';

const PromotionalBanner = () => {
  const theme = useTheme();

  return (
    <Box sx={{ marginTop: '40px' }}>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: '8px',
          color: theme.palette.primary.contrastText,
          padding: '40px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Box maxWidth="600px">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Torne-se um criador de <span style={{ color: theme.palette.text.title }}>boas memórias</span>.
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
              A plataforma de criação de <span style={{ color: theme.palette.text.title }}>experiências memoráveis</span>.
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '20px', fontSize: '16px', color: theme.palette.text.secondary }}>
              Junte-se aos produtores de memórias da Meta. Crie, publique e venda eventos ou conteúdos digitais.
            </Typography>
            <Button
              variant="outlined"
              startIcon={<WhatsAppIcon />}
              sx={{
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.primary.contrastText,
                textTransform: 'none',
                marginTop: '20px',
                '&:hover': { backgroundColor: theme.palette.primary.contrastText, color: theme.palette.primary.main },
              }}
            >
              Fale com um Consultor!
            </Button>
          </Box>
        </Box>

        <Box
          component="img"
          src={produtoreshome}
          alt="Destaque"
          sx={{
            position: 'absolute',
            bottom: '-20px',
            right: '20px',
            width: '100%',
            maxWidth: '300px',
            display: { xs: 'none', sm: 'block' },
            objectFit: 'contain',
          }}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" marginTop="40px" flexWrap="wrap" gap={2}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary, flex: '1', minWidth: '280px', borderRadius: '8px' }}>
          <CardContent>
            <BusinessCenterIcon sx={{ fontSize: '40px', color: theme.palette.text.secondary }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px', color: theme.palette.text.secondary }}>
              Gerente de Contas
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px', color: theme.palette.text.primary }}>
              Você precisa de um suporte lado a lado para acompanhar suas vendas e estratégias de venda.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.text.primary,
                borderColor: theme.palette.text.primary,
                textTransform: 'none',
                marginTop: '20px',
                '&:hover': { backgroundColor: theme.palette.text.primary, color: theme.palette.background.default },
              }}
            >
              Venda pela Meta
            </Button>
          </CardContent>
        </Card>

        <Card sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary, flex: '1', minWidth: '280px', borderRadius: '8px' }}>
          <CardContent>
            <MonetizationOnIcon sx={{ fontSize: '40px', color: theme.palette.text.secondary }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px', color: theme.palette.text.secondary }}>
              Taxas negociáveis
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px', color: theme.palette.text.primary }}>
              As taxas de serviços da Tixtor são justas e ajustáveis para o porte do seu evento.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.text.primary,
                borderColor: theme.palette.text.primary,
                textTransform: 'none',
                marginTop: '20px',
                '&:hover': { backgroundColor: theme.palette.text.primary, color: theme.palette.background.default },
              }}
            >
              Venda pela Meta
            </Button>
          </CardContent>
        </Card>

        <Card sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary, flex: '1', minWidth: '280px', borderRadius: '8px' }}>
          <CardContent>
            <EventAvailableIcon sx={{ fontSize: '40px', color: theme.palette.text.secondary }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px', color: theme.palette.text.secondary }}>
              Eventos Presenciais, Online e Cursos Digitais
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px', color: theme.palette.text.primary }}>
              Entre em contato e conheça todas as nossas soluções para sua produtora.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.text.primary,
                borderColor: theme.palette.text.primary,
                textTransform: 'none',
                marginTop: '20px',
                '&:hover': { backgroundColor: theme.palette.text.primary, color: theme.palette.background.default },
              }}
            >
              Venda pela Meta
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default PromotionalBanner;
