import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import api from "../../api";

const EditCoupon = () => {
  const { id, couponId } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    code: "",
    discount: "",
    valid_until: "",
    kind: "percentage",
    redemption_limit: "",
    event_batch_ids: [],
  });
  const [eventBatches, setEventBatches] = useState([]);

  useEffect(() => {
    const fetchCouponAndBatches = async () => {
      try {
        const [couponResponse, batchesResponse] = await Promise.all([
          api.get(`/producer/events/${id}/coupons/${couponId}`),
          api.get(`/producer/events/${id}/event_batches`),
        ]);

        setInitialValues({
          ...couponResponse.data,
          event_batch_ids: couponResponse.data.event_batch_ids || [],
        });

        setEventBatches(batchesResponse.data);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        alert("Erro ao carregar dados do cupom ou lotes de eventos.");
      }
    };

    fetchCouponAndBatches();
  }, [id, couponId]);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Código do cupom é obrigatório"),
    discount: Yup.number()
      .required("Desconto é obrigatório")
      .positive("Desconto deve ser positivo"),
    valid_until: Yup.date().required("Data de validade é obrigatória"),
    kind: Yup.string().oneOf(["percentage", "fixed"]).required("Tipo de desconto é obrigatório"),
    redemption_limit: Yup.number()
      .required("Limite de resgates é obrigatório")
      .positive("Limite deve ser positivo")
      .integer("Limite deve ser um número inteiro"),
    event_batch_ids: Yup.array()
      .of(Yup.string())
      .min(1, "Selecione pelo menos um lote de evento"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await api.put(`/producer/events/${id}/coupons/${couponId}`, { coupon: values });
      alert("Cupom atualizado com sucesso!");
      navigate(`/producer/events/${id}/coupons`);
    } catch (error) {
      console.error("Erro ao atualizar cupom:", error.response?.data || error.message);
      alert("Erro ao atualizar cupom. Verifique os campos e tente novamente.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Editar Cupom
        </Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Código do Cupom"
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                    fullWidth
                    error={touched.code && Boolean(errors.code)}
                    helperText={touched.code && errors.code}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Desconto (%) ou Valor Fixo"
                    name="discount"
                    value={values.discount}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    error={touched.discount && Boolean(errors.discount)}
                    helperText={touched.discount && errors.discount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Data de Validade"
                    name="valid_until"
                    value={values.valid_until}
                    onChange={handleChange}
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    error={touched.valid_until && Boolean(errors.valid_until)}
                    helperText={touched.valid_until && errors.valid_until}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Tipo do Desconto"
                    name="kind"
                    value={values.kind}
                    onChange={handleChange}
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    error={touched.kind && Boolean(errors.kind)}
                    helperText={touched.kind && errors.kind}
                  >
                    <option value="percentage">Porcentagem (%)</option>
                    <option value="fixed">Valor Fixo</option>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Limite de Resgates"
                    name="redemption_limit"
                    value={values.redemption_limit}
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    error={touched.redemption_limit && Boolean(errors.redemption_limit)}
                    helperText={touched.redemption_limit && errors.redemption_limit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Selecione os Lotes de Evento"
                    name="event_batch_ids"
                    value={values.event_batch_ids}
                    onChange={(e) =>
                      setFieldValue(
                        "event_batch_ids",
                        Array.from(e.target.selectedOptions, (option) => option.value)
                      )
                    }
                    fullWidth
                    select
                    SelectProps={{ multiple: true, native: true }}
                    error={touched.event_batch_ids && Boolean(errors.event_batch_ids)}
                    helperText={touched.event_batch_ids && errors.event_batch_ids}
                  >
                    {eventBatches.map((batch) => (
                      <option key={batch.id} value={batch.id}>
                        {batch.name} (R$ {(batch.price_in_cents / 100).toFixed(2)})
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Atualizar Cupom
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default EditCoupon;
