import React, { useState, useEffect } from "react";
import { CardMedia, Box } from "@mui/material";

const FeaturedEventBanner = ({ events }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [events]);

  if (!events || events.length === 0) return null;

  const currentEvent = events[currentIndex];

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "400px",
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: (theme) => theme.shadows[3],
      }}
    >
      <CardMedia
        component="img"
        image={currentEvent.desktop_photo_url || currentEvent.mobile_photo_url}
        alt={currentEvent.name}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transition: "opacity 0.5s ease-in-out",
        }}
      />
    </Box>
  );
};

export default FeaturedEventBanner;
