/* global Iugu */

import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
  Divider,
  useTheme,
  Alert,
  AlertTitle
} from "@mui/material";
import api from "./../api";

const PaymentModal = ({ orderId, onClose }) => {
  const [installments, setInstallments] = useState(1);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState("selectInstallments");
  const theme = useTheme();

  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");

  useEffect(() => {
    const fetchInstallmentOptions = async () => {
      try {
        const response = await api.get(`/orders/${orderId}/get_installment_options`);
        setInstallmentOptions(response.data);
      } catch (error) {
        console.error("Erro ao buscar opções de parcelamento:", error);
        alert("Erro ao buscar opções de parcelamento. Tente novamente.");
      }
    };
  
    fetchInstallmentOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);  

  const handleInstallmentsChange = (e) => {
    setInstallments(Number(e.target.value));
  };

  const handleSubmitInstallments = async () => {
    setSubmitting(true);
    try {
      const response = await api.post(`/orders/${orderId}/update_invoice_for_installments`, {
        installments
      });

      if (response.data.success) {
        alert(`Parcelamento selecionado: ${installments}x`);
        setStep("enterCardDetails");
      }
    } catch (error) {
      alert("Erro ao atualizar a fatura. Tente novamente.");
    } finally {
      setSubmitting(false);
    }
  };

  const handlePayment = async () => {
    setSubmitting(true);
  
    Iugu.setAccountID("AF69E82B63E749C4B588A6A83F791EDB");
    //Iugu.setTestMode(true); // Somente para testes, remova em produção
  
    const cardDetails = {
      number: cardNumber,
      verification_value: cvv,
      first_name: cardHolder.split(" ")[0],
      last_name: cardHolder.split(" ").slice(1).join(" "),
      month: expiryDate.split("/")[0],
      year: expiryDate.split("/")[1]
    };
  
    try {
      const response = await new Promise((resolve, reject) => {
        Iugu.createPaymentToken(cardDetails, (res) => {
          if (res.errors) {
            reject(res.errors);
          } else {
            resolve(res.id);
          }
        });
      });
  
      const paymentResponse = await api.post(`/orders/${orderId}/pay_with_card`, {
        card_token: response,
        installments
      });
  
      if (paymentResponse.data.success) {
        alert("Pagamento realizado com sucesso!");
        onClose();
      } else {
        alert("Erro no pagamento: " + paymentResponse.data.error);
      }
    } catch (error) {
      console.error("Erro ao processar pagamento:", error);
      alert("Erro ao processar o pagamento. Verifique os dados do cartão.");
    } finally {
      setSubmitting(false);
    }
  };  

  const renderInstallmentStep = () => (
    <>
      <Typography variant="h5" gutterBottom align="center" color="primary">
        Escolha o Parcelamento
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Alert severity="info" sx={{ mb: 3 }}>
        <AlertTitle>Segurança Garantida</AlertTitle>
        Todos os pagamentos são processados com criptografia e segurança máxima.
      </Alert>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="installments-label">Parcelas</InputLabel>
        <Select
          labelId="installments-label"
          id="installments"
          value={installments}
          onChange={handleInstallmentsChange}
        >
          {installmentOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmitInstallments}
        sx={{ textTransform: "none", mb: 2 }}
        disabled={submitting}
        startIcon={submitting && <CircularProgress size={20} color="inherit" />}
      >
        {submitting ? "Processando..." : "Confirmar Parcelamento"}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={onClose}
        sx={{ textTransform: "none" }}
      >
        Cancelar
      </Button>
    </>
  );

  const renderCardDetailsStep = () => (
    <>
      <Typography variant="h5" gutterBottom align="center" color="primary">
        Insira os Dados do Cartão
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <TextField
        fullWidth
        label="Número do Cartão"
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
        InputProps={{
            inputComponent: InputMask,
            inputProps: { mask: "9999 9999 9999 9999", maskChar: "" }
        }}
        sx={{ mb: 2 }}
        />
      <TextField
        fullWidth
        label="Nome no Cartão"
        value={cardHolder}
        onChange={(e) => setCardHolder(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Box display="flex" gap={2} sx={{ mb: 2 }}>
        <TextField
            label="Data de Expiração"
            placeholder="MM/AA"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            InputProps={{
                inputComponent: InputMask,
                inputProps: { mask: "99/99", maskChar: "" }
            }}
            fullWidth
            sx={{ mb: 2 }}
        />
        <TextField
            label="CVV"
            placeholder="123"
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
            InputProps={{
                inputComponent: InputMask,
                inputProps: { mask: "999", maskChar: "" }
            }}
            fullWidth
            sx={{ mb: 2 }}
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handlePayment}
        sx={{ textTransform: "none", mb: 2 }}
        disabled={submitting}
        startIcon={submitting && <CircularProgress size={20} color="inherit" />}
      >
        {submitting ? "Processando..." : "Pagar"}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => setStep("selectInstallments")}
        sx={{ textTransform: "none" }}
      >
        Voltar
      </Button>
    </>
  );

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 450,
          bgcolor: theme.palette.background.paper,
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxWidth: "95%"
        }}
      >
        {step === "selectInstallments" ? renderInstallmentStep() : renderCardDetailsStep()}
      </Box>
    </Modal>
  );
};

export default PaymentModal;
