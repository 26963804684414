import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";

const AddUserToProducer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users");
        setUsers(response.data);
      } catch (err) {
        console.error("Erro ao carregar usuários:", err);
        setError("Erro ao carregar usuários.");
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = users.filter((user) =>
        `${user.name} ${user.email}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [searchTerm, users]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUserId) {
      alert("Selecione um usuário para associar.");
      return;
    }

    setLoading(true);
    try {
      await api.post(`/producer/producers/${id}/add_user`, {
        user_id: selectedUserId,
      });
      alert("Usuário associado ao produtor com sucesso!");
      navigate("/producer");
    } catch (err) {
      console.error("Erro ao associar usuário:", err);
      alert("Erro ao associar usuário. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Associar Usuário ao Produtor
        </Typography>
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Pesquisar Usuário"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Digite o nome ou e-mail"
              />
              <List>
                {filteredUsers.map((user) => (
                  <ListItem
                    key={user.id}
                    button
                    selected={selectedUserId === user.id}
                    onClick={() => setSelectedUserId(user.id)}
                  >
                    <ListItemText primary={`${user.name} (${user.email})`} />
                  </ListItem>
                ))}
                {filteredUsers.length === 0 && !loading && (
                  <Typography color="textSecondary" variant="body2">
                    Nenhum usuário encontrado.
                  </Typography>
                )}
              </List>
              {loading && (
                <Box textAlign="center" mt={2}>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !selectedUserId}
              >
                {loading ? "Associando..." : "Associar Usuário"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default AddUserToProducer;
