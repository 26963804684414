import React from 'react';
import { Typography, Container, Box, Divider, Button, IconButton, Link, useTheme } from '@mui/material';
import { WhatsApp, Instagram, Lock, CreditCard } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box
      component="footer"
      sx={{
        background: 'linear-gradient(90deg, #ECE5F2 0%, #FFFFFF 100%)',
        color: theme.palette.text.primary,
        py: 4,
        mt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 2, mb: 3 }}>
          <IconButton href="https://instagram.com/meta.ingressos" target="_blank" sx={{ color: theme.palette.text.primary }}>
            <Instagram />
          </IconButton>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 2, mb: 3 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<WhatsApp />}
            href="https://wa.me/5535999655509"
            target="_blank"
            sx={{ textTransform: 'none', color: theme.palette.common.white }}
          >
            Dúvidas?
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<WhatsApp />}
            href="https://wa.me/5535999655509"
            target="_blank"
            sx={{ textTransform: 'none', color: theme.palette.common.white }}
          >
            Sou Produtor
          </Button>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 3, flexWrap: 'wrap', textAlign: 'center', mb: 3 }}>
          <Typography
            variant="body2"
            onClick={() => handleNavigation('/privacy-policy')}
            sx={{ color: theme.palette.text.primary, textDecoration: 'none', cursor: 'pointer' }}
          >
            Política de Privacidade
          </Typography>
          <Typography
            variant="body2"
            onClick={() => handleNavigation('/terms-of-use')}
            sx={{ color: theme.palette.text.primary, textDecoration: 'none', cursor: 'pointer' }}
          >
            Termos de Uso
          </Typography>
          <Typography
            variant="body2"
            onClick={() => handleNavigation('/refund-policy')}
            sx={{ color: theme.palette.text.primary, textDecoration: 'none', cursor: 'pointer' }}
          >
            Política de Reembolso
          </Typography>
          <Typography
            variant="body2"
            onClick={() => handleNavigation('/faq')}
            sx={{ color: theme.palette.text.primary, textDecoration: 'none', cursor: 'pointer', fontWeight: 'bold' }}
          >
            FAQ
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 2, mb: 3 }}>
          <Box display="flex" alignItems="center">
            <Lock sx={{ fontSize: 24, mr: 1, color: theme.palette.text.primary }} />
            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>Site Seguro</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CreditCard sx={{ fontSize: 24, mr: 1, color: theme.palette.text.primary }} />
            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>Pagamentos protegidos</Typography>
          </Box>
        </Box>

        <Divider sx={{ backgroundColor: theme.palette.text.primary, my: 2 }} />

        <Typography variant="body2" align="center" sx={{ mt: 3, color: theme.palette.text.primary }}>
          &copy; {new Date().getFullYear()} Metaingressos. Todos os direitos reservados.
        </Typography>
        <Typography variant="body2" align="center" sx={{ color: theme.palette.text.primary }}>
          Desenvolvido por{' '}
          <Link href="https://novamentesoftware.com" color={theme.palette.text.primary} target="_blank" rel="noopener">
            Novamente
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
