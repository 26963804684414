/* global loadTagManager */

import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Button, CircularProgress, Tooltip, Paper, Divider } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { OrderContext } from '../../contexts/OrderContext';
import { ContentCopy, CreditCard, CheckCircle, QrCode } from '@mui/icons-material';
import api from '../../api';
import PaymentModal from '../../components/PaymentModal';

const InvoicePage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { fetchOrderById, order } = useContext(OrderContext);
  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [hidePixPayment, setHidePixPayment] = useState(false);
  
  useEffect(() => {
    if (order?.producer?.tag_manager_id) {
      loadTagManager(order.producer.tag_manager_id);
    }
  }, [order?.producer?.tag_manager_id]);    

  useEffect(() => {
    const fetchOrGenerateInvoice = async () => {
      try {
        setLoading(true);
  
        const fetchedOrder = await fetchOrderById(orderId);
  
        if (!fetchedOrder) {
          console.error('Ordem não encontrada ou fetchOrderById retornou undefined.');
          return;
        }
  
        if (
          !fetchedOrder.invoice_id || 
          fetchedOrder.invoice_status === 'canceled' || 
          fetchedOrder.invoice_status === 'expired'
        ) {
          const response = await api.post(`/orders/${orderId}/generate_pix_invoice`);
          setInvoiceDetails(response.data);
        } else {
          setInvoiceDetails({
            invoice_pix_qrcode_url: fetchedOrder.invoice_pix_qrcode_url,
            invoice_pix_qrcode_text: fetchedOrder.invoice_pix_qrcode_text,
            secure_url: fetchedOrder.invoice_url,
          });
        }
      } catch (error) {
        console.error('Erro ao buscar ou gerar a fatura Pix:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrGenerateInvoice();
  }, [orderId, fetchOrderById]);  

  const generateInvoiceAfterCard = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/orders/${orderId}/pix_after_card`);
      setInvoiceDetails(response.data);
      fetchOrderById(orderId);
      alert('Pix gerado novamente com sucesso!');
    } catch (error) {
      console.error('Erro ao regerar fatura Pix:', error);
      alert('Erro ao regerar a fatura Pix. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };  

  const handleCopy = () => {
    navigator.clipboard.writeText(invoiceDetails.invoice_pix_qrcode_text);
    alert('Código Pix copiado para a área de transferência!');
  };

  const handleCheckPayment = async () => {
    try {
      const response = await api.get(`/orders/${orderId}/check_payment_status`);
      if (response.data.status === 'paid') {
        alert('O pagamento foi confirmado!');
        setIsPaid(true);
      } else {
        alert(`Status do pagamento: ${response.data.status}`);
      }
    } catch (error) {
      console.error('Erro ao verificar o status do pagamento:', error);
      alert('Erro ao verificar o status do pagamento. Tente novamente.');
    }
  };

  const handleCreditCardPayment = () => {
    setPaymentModalOpen(true);
    setHidePixPayment(true);
  };

  const handlePixAfterPayment = async () => {
    setHidePixPayment(false);
    setLoading(true);
    await generateInvoiceAfterCard();
    setLoading(false);
  };  

  const handleClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const handleViewTicket = () => {
    navigate('/profile');
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Gerando fatura Pix...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 700, mx: 'auto', borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom color="primary">
        Finalize sua Compra
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center" sx={{ mb: 3 }}>
        Pedido #{orderId} | Garanta seu ingresso agora mesmo!
      </Typography>

      {!hidePixPayment && (
        <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
          <Typography variant="h5" align="center" gutterBottom>
            <QrCode sx={{ fontSize: 40, color: 'secondary.main', mb: 1 }} />
            Pague com Pix
          </Typography>

          {invoiceDetails ? (
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ mb: 2 }}>
              <Tooltip title="Clique para copiar o código Pix">
                <Box
                  component="img"
                  src={invoiceDetails.invoice_pix_qrcode_url}
                  alt="QR Code Pix"
                  sx={{ width: 200, height: 200, cursor: 'pointer', borderRadius: 1, mb: 2 }}
                  onClick={handleCopy}
                />
              </Tooltip>
              <Typography variant="body2" color="textSecondary" align="center" sx={{ wordBreak: 'break-word', mb: 2 }}>
                Use o <strong>Pix Copia e Cola</strong> abaixo ou escaneie o QR Code acima:
              </Typography>
              <Tooltip title="Copiar código Pix">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<ContentCopy />}
                  onClick={handleCopy}
                  sx={{ textTransform: 'none', mb: 1 }}
                >
                  Copiar Código Pix
                </Button>
              </Tooltip>
            </Box>
          ) : (
            <Typography variant="body1" color="error" align="center">
              Erro ao carregar os detalhes da fatura.
            </Typography>
          )}

          <Divider sx={{ my: 2 }} />
          <Typography variant="body2" align="center" color="textSecondary">
            O pagamento é rápido, seguro e simplificado. Use o QR Code ou o código Pix acima.
          </Typography>
        </Paper>
      )}

      {isPaid ? (
        <Button
          variant="contained"
          color="success"
          startIcon={<CheckCircle />}
          onClick={handleViewTicket}
          fullWidth
          sx={{ mt: 4, py: 2 }}
        >
          Ver Meu Ingresso
        </Button>
      ) : (
        <>
          <Box display="flex" justifyContent="center" gap={2} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckCircle />}
              onClick={handleCheckPayment}
              sx={{ px: 4, py: 1 }}
            >
              Verificar e confirmar Pagamento
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<CreditCard />}
              onClick={handleCreditCardPayment}
              sx={{ px: 4, py: 1 }}
            >
              Pagar com
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<QrCode />}
              onClick={handlePixAfterPayment}
              sx={{ px: 4, py: 1 }}
              disabled={loading}
            >
              {loading ? 'Regerando Pix...' : 'Regerar Pix'}
            </Button>
          </Box>

          <Paper
            elevation={1}
            sx={{ p: 2, mt: 3, borderRadius: 1, backgroundColor: 'info.light', textAlign: 'center' }}
          >
            <Typography variant="body2" color="info.dark">
              Após a confirmação do pagamento, seu ingresso será disponibilizado em sua conta.
            </Typography>
          </Paper>
        </>
      )}

      {isPaymentModalOpen && (
        <PaymentModal
          orderId={orderId}
          onClose={handleClosePaymentModal}
        />
      )}
    </Box>
  );
};

export default InvoicePage;
