import React from "react";
import { Button } from "@mui/material";
import { Share as ShareIcon } from "@mui/icons-material";

const ShareEventButton = ({ eventName, eventDescription, eventUrl }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: eventName || "Evento",
          text: eventDescription || "Confira este evento incrível!",
          url: eventUrl || window.location.href,
        });
      } catch (error) {
        console.error("Erro ao compartilhar:", error);
        alert("Erro ao compartilhar o evento.");
      }
    } else {
      alert("A funcionalidade de compartilhamento não é suportada neste dispositivo.");
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<ShareIcon />}
      onClick={handleShare}
      size="small"
    >
      Compartilhar Evento
    </Button>
  );
};

export default ShareEventButton;
