import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import theme from './theme';
import AuthProvider from './contexts/AuthContext';
import EventProvider from './contexts/EventContext';
import OrderProvider from './contexts/OrderContext';
import ProducerContext from './contexts/ProducerContext';
import AppRoutes from './routes';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <EventProvider>
          <OrderProvider>
            <ProducerContext>
            <ThemeProvider theme={theme}>
              <Navbar />
              <AppRoutes />
              <Footer />
            </ThemeProvider>
            </ProducerContext>
          </OrderProvider>
        </EventProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;