import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import { Event } from "@mui/icons-material";

const ProducerDashboard = () => {
  const navigate = useNavigate();

  const actions = [
    {
      title: "Listar Eventos",
      description: "Veja todos os eventos criados",
      icon: <Event fontSize="large" />,
      onClick: () => navigate("/assistant/events"),
    },
  ];  

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard do Produtor
      </Typography>
      <Grid container spacing={3}>
        {actions.map((action, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card variant="outlined" sx={{ textAlign: "center", padding: 2 }}>
              <CardContent>
                <IconButton
                  onClick={action.onClick}
                  size="large"
                  sx={{ color: "primary.main", marginBottom: 1 }}
                >
                  {action.icon}
                </IconButton>
                <Typography variant="h6">{action.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {action.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProducerDashboard;
