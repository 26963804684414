import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import api from "../../api";
import { useProducer } from "../../contexts/ProducerContext";

const AssistantList = () => {
  const navigate = useNavigate();
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { producer, fetchCurrentProducer } = useProducer();

  useEffect(() => {
    if (!producer) {
      fetchCurrentProducer();
    }
  }, [producer, fetchCurrentProducer]);

  useEffect(() => {
    const fetchAssistants = async () => {
      if (!producer?.id) return;

      setLoading(true);
      setError(null);

      try {
        const response = await api.get(`/producer/producers/${producer.id}/list_users`);
        setAssistants(response.data);
      } catch (err) {
        console.error("Erro ao carregar assistentes:", err);
        setError("Erro ao carregar assistentes. Tente novamente.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssistants();
  }, [producer]);

  const handleAddAssistant = () => {
    if (producer?.id) {
      navigate(`/producer/assistant/${producer.id}/add-user`);
    } else {
      alert("ID do produtor não encontrado.");
    }
  };

  const handleRemoveAssistant = async (assistantId) => {
    if (!producer?.id) return alert("ID do produtor não encontrado.");

    try {
      await api.delete(`/producer/producers/${producer.id}/remove_user`, {
        params: { user_id: assistantId },
      });
      setAssistants((prev) => prev.filter((asst) => asst.id !== assistantId));
    } catch (err) {
      console.error("Erro ao remover assistente:", err);
      alert("Erro ao remover assistente.");
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Box mb={4}>
        <Card
          variant="outlined"
          sx={{
            textAlign: "center",
            padding: 2,
            cursor: "pointer",
            backgroundColor: "primary.main",
            color: "white",
          }}
          onClick={handleAddAssistant}
        >
          <CardContent>
            <IconButton size="large" sx={{ color: "white", marginBottom: 1 }}>
              <AddCircle fontSize="large" />
            </IconButton>
            <Typography variant="h6">Associar Novo Assistente</Typography>
          </CardContent>
        </Card>
      </Box>

      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Lista de Assistentes
        </Typography>
        <Grid container spacing={3}>
          {assistants.map((assistant) => (
            <Grid item xs={12} sm={6} md={4} key={assistant.id}>
              <Card variant="outlined" sx={{ textAlign: "center", padding: 2 }}>
                <CardContent>
                  <Typography variant="h6">{assistant.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {assistant.email}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<Delete />}
                      sx={{ width: "100%" }}
                      onClick={() => handleRemoveAssistant(assistant.id)}
                    >
                      Desassociar
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {assistants.length === 0 && (
          <Typography variant="body1" color="textSecondary" sx={{ textAlign: "center", mt: 4 }}>
            Nenhum assistente associado ainda. Clique em "Associar Novo Assistente" para começar.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default AssistantList;
