import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Paper, IconButton, InputAdornment, Grid, MenuItem } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import api from '../../api';

const RegisterPage = () => {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    name: '',
    document_number: '',
    cep: '',
    phone_number: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    state_id: '',
    city_id: '',
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get('/states');
        setStates(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };

    fetchStates();
  }, []);

  const fetchCities = async (stateId) => {
    try {
      const response = await api.get(`/states/${stateId}/cities`);
      setCities(response.data);
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
    }
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleStateChange = async (e) => {
    const selectedStateId = e.target.value;
    setUserData({ ...userData, state_id: selectedStateId, city_id: '' });
    await fetchCities(selectedStateId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await register(userData);
    if (success) {
      navigate('/login');
    } else {
      alert('Falha no registro');
    }
  };

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="background.default">
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%', bgcolor: 'background.paper' }}>
        <Typography variant="h4" component="h1" gutterBottom color="text.title" align="center">
          Cadastro
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField 
            label="Nome" 
            name="name" 
            value={userData.name} 
            onChange={handleChange} 
            fullWidth 
            margin="normal" 
            variant="outlined" 
            color="primary" 
          />
          <TextField
            label="Email"
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            inputProps={{
              style: { textTransform: 'lowercase' },
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            color="primary"
          />
          <TextField
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={userData.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            color="primary"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirmação da Senha"
            type={showPassword ? 'text' : 'password'}
            name="password_confirmation"
            value={userData.password_confirmation}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            color="primary"
          />
          
          <InputMask
            mask="999.999.999-99"
            value={userData.document_number}
            onChange={handleChange}
          >
            {(inputProps) => (
              <TextField 
                {...inputProps} 
                label="CPF" 
                name="document_number" 
                fullWidth 
                margin="normal" 
                variant="outlined" 
                color="primary" 
              />
            )}
          </InputMask>

          <InputMask
            mask="99999-999"
            value={userData.cep}
            onChange={handleChange}
          >
            {(inputProps) => (
              <TextField 
                {...inputProps} 
                label="CEP" 
                name="cep" 
                fullWidth 
                margin="normal" 
                variant="outlined" 
                color="primary" 
              />
            )}
          </InputMask>

          <InputMask
            mask="(99) 99999-9999"
            value={userData.phone_number}
            onChange={handleChange}
          >
            {(inputProps) => (
              <TextField 
                {...inputProps} 
                label="Telefone" 
                name="phone_number" 
                fullWidth 
                margin="normal" 
                variant="outlined" 
                color="primary" 
              />
            )}
          </InputMask>

          <TextField 
            label="Rua" 
            name="street" 
            value={userData.street} 
            onChange={handleChange} 
            fullWidth 
            margin="normal" 
            variant="outlined" 
            color="primary" 
          />
          <TextField 
            label="Número" 
            name="number" 
            value={userData.number} 
            onChange={handleChange} 
            fullWidth 
            margin="normal" 
            variant="outlined" 
            color="primary" 
          />
          <TextField 
            label="Complemento" 
            name="complement" 
            value={userData.complement} 
            onChange={handleChange} 
            fullWidth 
            margin="normal" 
            variant="outlined" 
            color="primary" 
          />
          <TextField 
            label="Bairro" 
            name="district" 
            value={userData.district} 
            onChange={handleChange} 
            fullWidth 
            margin="normal" 
            variant="outlined" 
            color="primary" 
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Estado"
                name="state_id"
                fullWidth
                value={userData.state_id}
                onChange={handleStateChange}
                variant="outlined"
                margin="normal"
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Cidade"
                name="city_id"
                fullWidth
                value={userData.city_id}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                disabled={!userData.state_id}
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2, py: 1.5 }}>
            Registrar
          </Button>
        </form>
        <Button variant="outlined" color="secondary" fullWidth sx={{ mt: 2 }} onClick={() => navigate('/login')}>
          Já possui uma conta? Login
        </Button>
      </Paper>
    </Box>
  );
};

export default RegisterPage;
