import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import InputMask from "react-input-mask";
import api from "../../../api";

const ProducerEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [producerData, setProducerData] = useState({
    name: "",
    slug: "",
    tag_manager_id: "",
    cnpj: "",
    contact_phone_1: "",
    contact_email: "",
    cep: "",
    street_name: "",
    street_number: "",
    neighborhood: "",
    address_complement: "",
    about: "",
    city_id: "",
    state_id: "",
    active: false,
    instagram: "",
    tiktok: "",
  });

  useEffect(() => {
    const fetchProducer = async () => {
      try {
        const response = await api.get(`/admin/producers/${id}`);
        setProducerData(response.data);
      } catch (error) {
        console.error("Erro ao carregar parceiro:", error);
        alert("Erro ao carregar parceiro.");
      }
    };

    fetchProducer();

    const fetchStates = async () => {
      try {
        const response = await api.get("/admin/states");
        setStates(response.data);
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };

    fetchStates();
  }, [id]);

  useEffect(() => {
    if (producerData.state_id) {
      const fetchCities = async () => {
        try {
          const response = await api.get(`/admin/states/${producerData.state_id}/cities`);
          setCities(response.data);
        } catch (error) {
          console.error("Erro ao carregar cidades:", error);
        }
      };

      fetchCities();
    } else {
      setCities([]);
    }
  }, [producerData.state_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProducerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.patch(`/admin/producers/${id}`, { producer: producerData });
      alert("Produtor atualizado com sucesso!");
      navigate("/admin/producers");
    } catch (error) {
      console.error("Erro ao atualizar produtor:", error);
      alert("Erro ao atualizar produtor. Verifique os dados.");
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Editar Produtor
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                name="name"
                fullWidth
                value={producerData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Instagram"
                name="instagram"
                fullWidth
                value={producerData.instagram}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="TikTok"
                name="tiktok"
                fullWidth
                value={producerData.tiktok}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="ID do Tag Manager"
                name="tag_manager_id"
                fullWidth
                value={producerData.tag_manager_id}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Slug"
                name="slug"
                fullWidth
                value={producerData.slug}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="99.999.999/9999-99"
                value={producerData.cnpj}
                onChange={(e) => setProducerData({ ...producerData, cnpj: e.target.value })}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label="CNPJ"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="(99) 99999-9999"
                value={producerData.contact_phone_1}
                onChange={(e) => setProducerData({ ...producerData, contact_phone_1: e.target.value })}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label="Telefone de Contato"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="E-mail de Contato"
                name="contact_email"
                fullWidth
                value={producerData.contact_email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sobre"
                name="about"
                multiline
                rows={4}
                fullWidth
                value={producerData.about}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="99999-999"
                value={producerData.cep}
                onChange={(e) => setProducerData({ ...producerData, cep: e.target.value })}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label="CEP"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Rua"
                name="street_name"
                fullWidth
                value={producerData.street_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Número"
                name="street_number"
                fullWidth
                value={producerData.street_number}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                name="address_complement"
                fullWidth
                value={producerData.address_complement}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bairro"
                name="neighborhood"
                fullWidth
                value={producerData.neighborhood}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Estado"
                name="state_id"
                fullWidth
                value={producerData.state_id}
                onChange={(e) => {
                  handleInputChange(e);
                  setProducerData({ ...producerData, city_id: "" });
                }}
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Cidade"
                name="city_id"
                fullWidth
                value={producerData.city_id}
                onChange={handleInputChange}
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={producerData.active}
                    onChange={(e) => setProducerData({ ...producerData, active: e.target.checked })}
                    name="active"
                    color="primary"
                  />
                }
                label={producerData.active ? "Ativo" : "Inativo"}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", marginTop: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
              >
                Salvar Alterações
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default ProducerEdit;
