/* global loadTagManager */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography, Container, CardMedia, Box, Grid, Paper, Stack,
} from '@mui/material';
import { CalendarToday, LocationOn, Home } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import api from '../../api';
import EventOrderItems from '../../components/EventOrderItems';
import EventProducer from "../../components/EventProducer";
import ShareEventButton from "../../components/ShareEventButton";

const EventDetailsPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (event?.producer?.tag_manager_id) {
      loadTagManager(event.producer.tag_manager_id);
    }
  }, [event?.producer?.tag_manager_id]);  

  useEffect(() => {
    api
      .get(`/events/${id}`)
      .then((response) => {
        setEvent(response.data);
      })
      .catch((error) => console.error('Error fetching event', error));
  }, [id]);

  if (!event) return <p>Loading...</p>;

  const formatDateTime = (datetime) => {
    if (!datetime) return 'Não disponível';
    const date = new Date(datetime);
    return date.toLocaleString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const parseStateAndCity = (location) => {
    if (!location) return '';
    const regex = /,\s*([^,]+)\s*-\s*([A-Z]{2})/;
    const match = location.match(regex);
    return match ? `${match[2]} - ${match[1]}` : location;
  };

  const parseAddress = (location) => {
    if (!location) return '';
    const regex = /(.+),\s*[^,]+-\s*[A-Z]{2}/;
    const match = location.match(regex);
    return match ? match[1] : location;
  };

  return (
    <div>
      {(event.desktop_photo_url || event.mobile_photo_url) && (
        <CardMedia
          component="img"
          image={isMobile ? event.mobile_photo_url : event.desktop_photo_url}
          alt="Event image"
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
          }}
        />
      )}

      <Container maxWidth="lg" style={{ padding: '20px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" color="primary" gutterBottom>
              {event.name}
            </Typography>

            <Paper elevation={2} style={{ padding: '15px', marginBottom: '20px' }}>
              <Stack spacing={2}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <CalendarToday sx={{ marginRight: 1, color: 'orange' }} />
                    <Typography variant="body1">
                      <strong>Início:</strong> {formatDateTime(event.scheduled_start)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Home sx={{ marginRight: 1, color: 'orange' }} />
                  <Typography variant="body1">
                    <strong>Local:</strong> {parseAddress(event.location)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LocationOn sx={{ marginRight: 1, color: 'orange' }} />
                  <Typography variant="body1">
                    <strong>Estado e Cidade:</strong> {parseStateAndCity(event.location)}
                  </Typography>
                </Box>
              </Stack>
            </Paper>

            <Paper
              elevation={1}
              style={{
                padding: '15px',
                marginBottom: '20px',
                backgroundColor: '#f7f9fc',
              }}
            >
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  lineHeight: 1.6,
                }}
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </Paper>

            <EventProducer producer={event.producer} />
          </Grid>

          <Grid item xs={12} md={5}>
            <Box position="sticky" top="20px">
              <EventOrderItems
                event={event}
                eventBatches={event.event_batches}
                feePercentage={event.fee_percentage}
                insuranceEnabled={event.insurance_enabled}
                insurancePriceInCents={event.insurance_price_in_cents}
              />
              <Box mt={2} textAlign="center">
                <ShareEventButton
                  eventName={event.name}
                  eventDescription={event.description}
                  eventUrl={window.location.href}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EventDetailsPage;
