import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import api from "../../../api";

const UpdateInsuranceDetails = ({ eventId: propEventId }) => {
  const { id } = useParams();
  const eventId = propEventId || id;
  const [insurancePriceInCents, setInsurancePriceInCents] = useState('');
  const [insuranceStatus, setInsuranceStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (!eventId) {
      setMessage('Evento não encontrado.');
      setInitialLoading(false);
      return;
    }
  
    const fetchEventDetails = async () => {
      try {
        const response = await api.get(`/admin/events/${eventId}`);
        const event = response.data.event;
        setInsurancePriceInCents(event.insurance_price_in_cents ?? '');
        setInsuranceStatus(event.insurance_enabled ?? false);
      } catch (error) {
        console.error('Erro ao carregar detalhes do evento:', error);
        setMessage('Erro ao carregar seguro do evento.');
      } finally {
        setInitialLoading(false);
      }
    };
  
    fetchEventDetails();
  }, [eventId]);  

  const handleUpdate = async () => {
    if (insurancePriceInCents === '') {
      setMessage('Insira uma taxa válida.');
      return;
    }
  
    setLoading(true);
    setMessage('');
  
    try {
      const response = await api.patch(`/admin/events/${eventId}/update_insurance_details`, {
        insurance_price_in_cents: parseFloat(insurancePriceInCents),
        insurance_enabled: insuranceStatus === 'enabled'
      });
      setMessage(response.data.message || 'Seguro atualizado com sucesso.');
    } catch (error) {
      setMessage('Erro ao atualizar o seguro. Verifique os dados e tente novamente.');
      console.error('Erro ao atualizar seguro:', error);
    } finally {
      setLoading(false);
    }
  };  

  if (initialLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>Carregando...</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Alterar Valor do Seguro
        </Typography>
        {eventId ? (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Evento ID: {eventId}
            </Typography>
            <TextField
              label="Novo valor do seguro (%)"
              type="number"
              value={insurancePriceInCents}
              onChange={(e) => setInsurancePriceInCents(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
                label="Status do Seguro"
                type="text"
                value={insuranceStatus}
                onChange={(e) => setInsuranceStatus(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleUpdate}
              disabled={loading}
            >
              {loading ? 'Atualizando...' : 'Atualizar Valor do Seguro'}
            </Button>
          </>
        ) : (
          <Typography variant="body1" color="error" align="center">
            Evento não encontrado.
          </Typography>
        )}
        {message && (
          <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default UpdateInsuranceDetails;
