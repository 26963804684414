import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import api from "../api";
import moment from "moment";

const batchSchema = Yup.object().shape({
  pass_type: Yup.string().required("Tipo de ingresso é obrigatório"),
  name: Yup.string().required("Nome é obrigatório"),
  price_in_cents: Yup.number()
    .required("Preço é obrigatório")
    .min(0, "Deve ser maior ou igual a 0"),
  quantity: Yup.number()
    .required("Quantidade é obrigatória")
    .min(1, "Deve ser maior ou igual a 1"),
  number_of_accesses_granted: Yup.number()
    .required("Multiplicador de Ingressos é obrigatório")
    .min(1, "Deve ser maior ou igual a 1"),
  ends_at: Yup.date().required("Data limite é obrigatória"),
});

const EventBatches = ({ eventId }) => {
  const [initialValues, setInitialValues] = useState({ event: { event_batches: [] } });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/admin/events/${eventId}/event_batches`);
        const eventBatches = response.data;

        const filteredBatches = eventBatches.map(eb => ({
          id: eb.id,
          pass_type: eb.pass_type || "",
          name: eb.name || "",
          price_in_cents: eb.price_in_cents || 0,
          quantity: eb.quantity || 0,
          number_of_accesses_granted: eb.number_of_accesses_granted || 1,
          ends_at: eb.ends_at ? eb.ends_at.split("T")[0] : moment().format("YYYY-MM-DD"),
          order: eb.order
        }));

        setInitialValues({ event: { event_batches: filteredBatches } });
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar lotes:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  const handleSubmit = async (values) => {
    try {
      await api.post(`/admin/events/${eventId}/event_batches/update_all`, values);
      alert("Dados salvos com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar dados:", error);
      alert("Erro ao salvar dados.");
    }
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        event: Yup.object({
          event_batches: Yup.array().of(batchSchema),
        }),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched }) => (
        <Form>
          <FieldArray name="event.event_batches">
            {({ push, remove }) => (
              <Box>
                {values.event.event_batches.map((batch, index) => (
                  <Box
                    key={index}
                    mb={4}
                    p={2}
                    border={1}
                    borderRadius={2}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={10}>
                        <Typography variant="subtitle1">
                          {`Lote ${index + 1}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          color="error"
                          onClick={() => remove(index)}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name={`event.event_batches[${index}].pass_type`}
                          label="Tipo de Ingresso"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name={`event.event_batches[${index}].name`}
                          label="Nome do Lote"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name={`event.event_batches[${index}].price_in_cents`}
                          label="Preço (em centavos)"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name={`event.event_batches[${index}].quantity`}
                          label="Quantidade"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name={`event.event_batches[${index}].number_of_accesses_granted`}
                          label="Multiplicador de Ingressos"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name={`event.event_batches[${index}].ends_at`}
                          label="Data Limite"
                          fullWidth
                          type="date"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircle />}
                  onClick={() =>
                    push({
                      pass_type: "",
                      name: "",
                      price_in_cents: 0,
                      quantity: 0,
                      ends_at: moment().format("YYYY-MM-DD"),
                    })
                  }
                >
                  Adicionar Lote
                </Button>
              </Box>
            )}
          </FieldArray>
          <Box mt={4}>
            <Button type="submit" variant="contained" color="success">
              Salvar Alterações
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EventBatches;
