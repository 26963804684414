import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import { Event, AddCircle } from "@mui/icons-material";
import { useProducer } from "../../contexts/ProducerContext";

const ProducerDashboard = () => {
  const navigate = useNavigate();
  const { producer, fetchCurrentProducer, loading, error } = useProducer();

  useEffect(() => {
    fetchCurrentProducer();
  }, [fetchCurrentProducer]);

  if (loading) {
    return <Typography>Carregando dados do produtor...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const actions = [
    {
      title: "Listar Eventos",
      description: "Veja todos os eventos criados",
      icon: <Event fontSize="large" />,
      onClick: () => navigate("/producer/events"),
    },
    {
      title: "Assistentes",
      description: "Gerencie os assistentes da produtora",
      icon: <AddCircle fontSize="large" />,
      onClick: () => navigate(`/producer/assistant`),
    },
  ];

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard do Produtor: {producer?.name || "Nome do Produtor"}
      </Typography>
      <Grid container spacing={3}>
        {actions.map((action, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card variant="outlined" sx={{ textAlign: "center", padding: 2 }}>
              <CardContent>
                <IconButton
                  onClick={action.onClick}
                  size="large"
                  sx={{ color: "primary.main", marginBottom: 1 }}
                >
                  {action.icon}
                </IconButton>
                <Typography variant="h6">{action.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {action.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProducerDashboard;