import React, { useContext, useState } from 'react';
import { EventContext } from '../contexts/EventContext';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PromotionalBanner from '../components/PromotionalBanner';
import FeaturedEventBanner from "../components/FeaturedEventBanner";

const HomePage = () => {
  const { events } = useContext(EventContext);
  const [search, setSearch] = useState('');
  const [filteredCategory] = useState('');
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCardClick = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  const parseLocation = (location) => {
    if (!location) return '';
    const regex = /,\s*([^,]+)\s*-\s*([A-Z]{2})/;
    const match = location.match(regex);
    return match ? `${match[1]}, ${match[2]}` : location;
  };

  const formatDateTime = (datetime) => {
    if (!datetime) return 'Data não disponível';
    const date = new Date(datetime);
    if (isNaN(date)) return 'Data inválida';
    return date.toLocaleString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const filteredEvents = events.filter((event) => {
    const matchesSearch =
      event.name.toLowerCase().includes(search.toLowerCase()) ||
      event.location.toLowerCase().includes(search.toLowerCase());
    const matchesCategory = filteredCategory ? event.category === filteredCategory : true;

    return matchesSearch && matchesCategory;
  });

  const bannerEvents = events.slice(0, 3);

  return (
    <Box paddingX={{ xs: 2, sm: 3, md: 4 }} paddingY={5}>
      {bannerEvents.length > 0 && (
        <Box mb={4}>
          <FeaturedEventBanner events={bannerEvents} />
        </Box>
      )}

      <Typography variant="h5" align="center" gutterBottom>
        Crie <span style={{ fontWeight: 'bold' }}>memórias</span> para cada parte da sua vida!
      </Typography>

      <TextField
        label="Filtrar eventos por nome ou cidade"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Grid container spacing={3}>
        {filteredEvents.length > 0 ? (
          filteredEvents.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card
                onClick={() => handleCardClick(event.id)}
                sx={{
                  cursor: 'pointer',
                  borderRadius: 2,
                  overflow: 'hidden',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
              >
                {(event.desktop_photo_url || event.mobile_photo_url) && (
                  <CardMedia
                    component="img"
                    objectfit= 'contain'
                    alt={event.name}
                    height="auto"
                    image={isMobile ? event.mobile_photo_url : event.desktop_photo_url}
                  />
                )}
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {event.name}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}
                  >
                    <LocationOnIcon fontSize="small" sx={{ marginRight: 1 }} />
                    {parseLocation(event.location)}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}
                  >
                    <AccessTimeIcon fontSize="small" sx={{ marginRight: 1 }} />
                    {formatDateTime(event.scheduled_start)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1">Não há eventos disponíveis no momento.</Typography>
        )}
      </Grid>

      <PromotionalBanner />
    </Box>
  );
};

export default HomePage;
