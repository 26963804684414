import React from 'react';
import { Paper, Typography, Button, Grid } from '@mui/material';
import { Download, Event } from '@mui/icons-material';
import { generatePDF } from '../utils/pdfGenerator';

const CompletedPasses = ({ passes, onViewPass }) => {
  if (passes.length === 0) {
    return <Typography variant="body2">Você ainda não tem ingressos concluídos.</Typography>;
  }

  return (
    <>
      {passes.map((pass) => (
        <Paper elevation={2} sx={{ p: 2, mt: 2, mb: 3 }} key={pass.id}>
          <Typography variant="h6" color="primary">
            {pass.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {pass.date} - {pass.location}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Criado em: {pass.createdAt}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Download />}
              onClick={() => generatePDF(pass.id)}
            >
              Baixar Ingresso
            </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Event />}
                onClick={() => onViewPass(pass.id)}
              >
                Visualizar Ingresso
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
};

export default CompletedPasses;
